import qs from 'qs';
import axios from 'axios';
import {wrappedGET, wrappedPOST} from '../helpers/fetcher';

const TYPES = {
  REQUEST: 'supplierExchange/request',
  RESPONSE: 'supplierExchange/response',
  ERROR: 'supplierExchange/error',

  UPDATE_STATUS_REQUEST: 'supplierExchange/updateRequest',
  UPDATE_STATUS_RESPONSE: 'supplierExchange/updateResponse',

  SET_SUPPLIER: 'supplierExchange/setSupplier',
};

const fetchSupplierExchangeRequest = () => ({
  type: TYPES.REQUEST,
});

const fetchSupplierExchangeResponse = (data) => ({
  type: TYPES.RESPONSE,
  data,
});

const fetchSupplierExchangeError = (error) => ({
  type: TYPES.ERROR,
  error,
});

const updateSupplierStatusRequest = () => ({
  type: TYPES.UPDATE_STATUS_REQUEST,
});

const updateSupplierStatusResponse = (data) => ({
  type: TYPES.UPDATE_STATUS_RESPONSE,
  data,
});

export const fetchSupplierExchange = (offeringCompanyId, supplierId) => (
  dispatch
) => {
  dispatch(fetchSupplierExchangeRequest());
  return wrappedGET(
    `/api/suppliers/${supplierId}?offeringCompanyId=${offeringCompanyId}`
  )
    .then((res) => {
      if (res.status !== 200) {
        dispatch(fetchSupplierExchangeError(res.statusText));
      } else {
        dispatch(fetchSupplierExchangeResponse(res.data));
      }
      return res.data;
    })
    .catch((error) => {
      console.error(error);
      dispatch(fetchSupplierExchangeError(error.message));
    });
};

export const postSupplier = (formData, getParameters) => (dispatch) => {
  dispatch(updateSupplierStatusRequest());
  const params = qs.stringify(getParameters);
  return axios
    .post(`/api/suppliers?${params}`, formData, {
      // for some reason, after our latest dependency upgrade axios stops
      // serializing FormData automatically to form data and started sending json
      // result is a 403 from the backend in spring for some reason too :)
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => {
      if (res.status !== 200) {
        dispatch(fetchSupplierExchangeError(res.statusText));
      } else {
        dispatch(updateSupplierStatusResponse(res.data));
      }
    })
    .catch((error) => dispatch(fetchSupplierExchangeError(error.message)));
};

export const updateSupplierStatus = (supplierId, newState, params) => (
  dispatch
) => {
  dispatch(updateSupplierStatusRequest());

  const query = qs.stringify(params);
  return wrappedPOST(`/api/suppliers/${supplierId}/status/${newState}?${query}`)
    .then((res) => {
      if (res.status !== 200) {
        dispatch(fetchSupplierExchangeError(res.statusText));
      } else {
        dispatch(updateSupplierStatusResponse(res.data));
      }
      return res.data;
    })
    .catch((error) => dispatch(fetchSupplierExchangeError(error.message)));
};

export const setSupplier = (supplier) => (dispatch) => {
  dispatch({
    type: TYPES.SET_SUPPLIER,
    data: supplier,
  });
};

const initialState = {
  supplier: null,
  configuration: null,
  error: null,
  loading: true,
  uploading: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.REQUEST:
      return {
        ...state,
        supplier: null,
        loading: true,
        error: null,
      };

    case TYPES.RESPONSE:
      return {
        ...state,
        loading: false,
        error: null,
        ...action.data,
      };
    case TYPES.ERROR:
      return {
        ...state,
        loading: false,
        updating: false,
        error: action.error,
      };
    case TYPES.UPDATE_STATUS_REQUEST:
      return {
        ...state,
        updating: true,
        error: null,
      };
    case TYPES.UPDATE_STATUS_RESPONSE:
      // eslint-disable-next-line no-case-declarations
      const sup = {
        ...action.data,
        categories: state.supplier.categories,
      };

      return {
        ...state,
        updating: false,
        error: null,
        supplier: sup,
      };

    case TYPES.SET_SUPPLIER:
      return {
        ...state,
        supplier: action.data,
      };

    default:
      return state;
  }
};
